html{ 
  font-size: 62.5%;

  @media only screen and (max-width: $bp-big) {
    font-size: 56.25%;
  }

  @media only screen and (max-width: $bp-medium) {
    font-size: 50%;
  }
}

body{
  font-family: 'Poppins', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;
  color: $color-grey;
}

h1,
h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}

h1 {
  font-size: 3rem;

  @media only screen and (max-width: $bp-smallest) {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 2.2rem;

  @media only screen and (max-width: $bp-smallest) {
    font-size: 2rem;
  }
}