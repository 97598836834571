$bp-largest: 1800px;
$bp-large: 1400px;
$bp-big: 1000px;
$bp-medium: 800px;
$bp-small: 600px;
$bp-smallest: 500px;

$color-orange: #ffa214;
$color-blue: #016aad;
$color-grey: #333;
$color-white: #fff;
$color-purple: #960cd1;

@keyframes moveTitle {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0);
  }
}