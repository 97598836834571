.navbar {
  width: 100%;
  background-color: $color-white;
  transform: translateY(-100%);
  transition: transform .5s;
  font-size: 1.5rem;
  box-shadow: 0px 6px 10px 2px rgba(0,0,0,0.25);

  & > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  &.show {
    transform: translateY(0);
  }
}

.nav {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70rem;
  max-width: 100%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  font-size: 2rem;

  @media only screen and (max-width: $bp-medium) {
    padding: 3rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 1.5rem;
  }

  .btn {
    margin: 1rem 0;
  }

  &.hide {
    display: none;
  }
}

.bg-symbol {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 75rem;
  max-width: 80%;
  opacity: 1;
  transition: transform 0.5s;
  z-index: 1;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}

.page {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.show {
    overflow-y: auto;
  }

  &--landing {
    background-image: url(../img/bg-purple.jpg);
    left: 0;
    overflow: hidden;
  }

  &--left,
  &--right {
    transition: transform 0.5s;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  &--left {
    background-image: url(../img/bg-orange.jpg);
    left: -100%;
    transform: translateX(0);

    &.reveal {
      transform: translateX(50%);
    }

    &.show {
      transform: translateX(100%);
    }
  }

  &--right {
    background-image: url(../img/bg-blue.jpg);
    right: -100%;
    transform: translateX(0);

    &.reveal {
      transform: translateX(-50%);
    }

    &.show {
      transform: translateX(-100%);
    }
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    user-select: none;
    z-index: -1;
  }

  &__content {
    margin-top: 50vh;
    transform: translateY(50vh);
    transition: transform .3s;

    @media only screen and (max-width: $bp-medium) {
      margin-top: 25vh;
      transform: translateY(0);
    }

    &.show {
      transform: translateY(0);
    }
  }

  &__title {
    width: max-content;
    color: rgba(255, 255, 255, 0.1);
    font-size: 25vh;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;

    &.animate {
      animation: moveTitle 180s infinite linear;
    }
  }
}

.section {
  padding: 10rem 5rem;

  @media only screen and (max-width: $bp-medium) {
    padding: 5rem 3rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 3rem 1.5rem;
  }

  &--bg-white {
    background-color: $color-white;
  }

  &--bg-series {
    background-image: linear-gradient(rgba(255, 222, 172, 0.9), rgba(255, 222, 172, 0.3)), url(../img/seria.JPG);
    background-image: url(../img/seria.JPG);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--bg-blurbs {
    background-image: linear-gradient(rgba(189, 193, 235, 0.2), rgba(189, 193, 235, 0.9), rgba(189, 193, 235, 0.2)), url(../img/seria-2.jpg);
    background-image: url(../img/seria-2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  & .btn {
    font-size: 2rem;
  }
}

.container {
  max-width: 100rem;
  margin: 0 auto;

  &--center {
    text-align: center;
  }
}

.book {
  display: flex;
  padding: 5rem;
  max-width: 100rem;
  margin: 10rem auto;
  background-color: $color-white;
  align-items: center;
  box-shadow: 0 4px 4px rgba($color: $color-grey, $alpha: 0.3);

  @media only screen and (max-width: $bp-medium) {
    padding: 3rem;
    margin: 5rem auto;
  }

  @media only screen and (max-width: $bp-small) {
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 3rem 1.5rem;
    margin: 3rem auto;
  }

  &__description {
    flex: 1 1;
    margin-right: 5rem;

    @media only screen and (max-width: $bp-small) {
      margin-right: 0;
      margin-top: 3rem;
    }
  }

  &__cover {
    width: 30rem;

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & ul {
    list-style: inside;
  }
}

.fragment {

  &__text {
    text-align: center;
    display: none;

    .btn {
      margin-top: 2rem;
    }
  }

  &.show {
    .fragment__text {
      display: block;
    }

    .form {
      display: none;
    }
  }
}

.series {

  &__books {
    display: flex;
    justify-content: center;
    margin-top: 5rem;

    @media only screen and (max-width: $bp-smallest) {
      margin-top: 3rem;
    }
  }

  &__book {
    width: 25rem;
    box-shadow: 0px 6px 10px 2px rgba(0,0,0,0.75);
    transition: transform .3s;

    &:hover {
      transform: scale(1.1);
    }

    &:not(:last-child) {
      margin-right: 2rem;

      @media only screen and (max-width: $bp-small) {
        margin-right: 1.5rem;
      }

      @media only screen and (max-width: $bp-smallest) {
        margin-right: 1rem;
      }
    }

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.blurbs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;

  @media only screen and (max-width: $bp-small) {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media only screen and (max-width: $bp-smallest) {
    margin-top: 3rem;
  }
}

.blurb {
  width: 30rem;
  background-color: rgba($color-blue, .7);
  color: $color-white;
  padding: 1rem;
  box-shadow: 0px 6px 10px 2px rgba(0,0,0,0.75);

  &__quote {
    font-size: 1.8rem;
  }

  @media only screen and (max-width: $bp-small) {
    width: 100%;
  }

  &:not(:last-child) {
    margin-right: 2rem;

    @media only screen and (max-width: $bp-small) {
      margin-right: 0;
      margin-top: 1.5rem;
    }
  }
}

.slider {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  transition: top .3s;
  visibility: hidden;
  z-index: 2;

  &.show {
    top: 0;
    visibility: visible;

    .slider__nav-btn,
    .slider__close-bar {
      visibility: visible;
      opacity: 1;
    }
  }

  &__nav-btn {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s 0.3s;
    z-index: 2;

    &--left {
      left: 5rem;

      @media only screen and (max-width: $bp-medium) {
        left: 2rem;
      }

      @media only screen and (max-width: $bp-small) {
        left: 1rem;
      }

      @media only screen and (max-width: $bp-smallest) {
        left: 0;
      }
    }

    &--right {
      right: 5rem;

      @media only screen and (max-width: $bp-medium) {
        right: 2rem;
      }

      @media only screen and (max-width: $bp-small) {
        right: 1rem;
      }

      @media only screen and (max-width: $bp-smallest) {
        right: 0;
      }
    }

    & img {
      width: auto;
    }
  }

  &__close-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 10rem;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s 0.5s;
    z-index: 2;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
    }

    @media only screen and (max-width: $bp-small) {
      height: 7rem;
    }
  }

  &__nav-btn-close {
    width: 5rem;
    height: 5rem;
    border: 1px solid $color-grey;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & img {
      width: auto;
    }
  }
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &__header {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 25vh;
  }

  &#synopsis-1 .slide__header {
    background-image: url(../img/bg-blue.jpg);
  }

  &#synopsis-2 .slide__header {
    background-image: url(../img/bg-red.jpg);
  }

  &#synopsis-3 .slide__header {
    background-image: url(../img/bg-purple.jpg);
  }

  &__title {
    width: max-content;
    color: rgba(255, 255, 255, 0.15);
    font-size: 25vh;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;

    &.animate {
      animation: moveTitle 180s infinite linear;
    }
  }

  &__content {
    max-width: 70ch;
    margin: 0 auto;
    padding: 5rem 5rem 15rem;

    @media only screen and (max-width: $bp-medium) {
      padding: 5rem 5rem 13rem;
    }

    @media only screen and (max-width: $bp-small) {
      padding: 3rem 5rem 10rem;
    }
  }

  &__info {
    display: none;
    margin-bottom: 5rem;

    & h2 {
      line-height: 1.5;
    }
  }

  &.originals .slide__info {
    display: block;
  }
}