.btn {
  padding: 1em 1em;
  background-color: $color-white;
  text-transform: uppercase;
  font-size: inherit;
  position: relative;
  display: inline-block;
  font-family: 'MedievalSharp', cursive;
  line-height: 1;

  & > span {
    color: $color-grey;
    mix-blend-mode: multiply;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: all 0.3s;
  }

  &:hover::before {
    width: 100%;
  }

  &--left::before {
    left: 0;
  }

  &--right::before {
    right: 0;
  }

  &--orange::before {
    background-color: $color-orange;
  }

  &--blue::before {
    background-color: $color-blue;
  }

  &--orange-border {
    border: 1px solid $color-orange;
  }

  &--blue-border {
    border: 1px solid $color-blue;
  }

  &.submit {
    margin-top: 1rem;
  }
}

.link {
  background-color: $color-blue;
  color: $color-white;
  transition: all .3s;

  &:hover,
  &:focus {
    background-color: $color-orange;
  }
}

.buy-links {
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $bp-smallest) {
    flex-direction: column;
  }

  & > .btn {
    margin: 1rem;
  }
}

.banner {
  box-shadow: 0px 6px 10px 2px rgba(0,0,0,0.25);
}